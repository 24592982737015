const Facebook = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4953 0.922852H2.47398C1.61722 0.923367 0.92268 1.61825 0.922852 2.47536V27.4967C0.923367 28.3535 1.61825 29.048 2.47536 29.0479H15.9467V18.1714H12.2937V13.9142H15.9467V10.7812C15.9467 7.14798 18.1647 5.17044 21.4057 5.17044C22.958 5.17044 24.292 5.28614 24.6808 5.33781V9.13513H22.4459C20.6826 9.13513 20.3412 9.97301 20.3412 11.2028V13.9142H24.5572L24.0079 18.1714H20.3412V29.0479H27.4953C28.3526 29.048 29.0477 28.3533 29.0479 27.496C29.0479 27.4959 29.0479 27.4957 29.0479 27.4953V2.47398C29.0475 1.61722 28.3525 0.92268 27.4953 0.922852Z"
      fill="white"
    />
  </svg>
);

export default Facebook;
