const Close = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.7485 15.0248L29.4299 3.34306C30.1901 2.58321 30.1901 1.35464 29.4299 0.59479C28.67 -0.16506 27.4415 -0.16506 26.6816 0.59479L14.9998 12.2765L3.31843 0.59479C2.55822 -0.16506 1.33001 -0.16506 0.570155 0.59479C-0.190052 1.35464 -0.190052 2.58321 0.570155 3.34306L12.2516 15.0248L0.570155 26.7066C-0.190052 27.4664 -0.190052 28.695 0.570155 29.4548C0.948834 29.8339 1.44674 30.0243 1.94429 30.0243C2.44184 30.0243 2.93939 29.8339 3.31843 29.4548L14.9998 17.7731L26.6816 29.4548C27.0606 29.8339 27.5582 30.0243 28.0557 30.0243C28.5533 30.0243 29.0508 29.8339 29.4299 29.4548C30.1901 28.695 30.1901 27.4664 29.4299 26.7066L17.7485 15.0248Z"
        fill="#682C8F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Close;
