const Arrow = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5559 9.2196L6.65567 0.31951C6.44982 0.113495 6.17502 0 5.88202 0C5.58901 0 5.31422 0.113495 5.10837 0.31951L4.45292 0.974789C4.02642 1.40178 4.02642 2.09576 4.45292 2.52209L11.9267 9.99585L4.44463 17.4779C4.23878 17.6839 4.12512 17.9586 4.12512 18.2514C4.12512 18.5446 4.23878 18.8192 4.44463 19.0254L5.10007 19.6805C5.30609 19.8865 5.58072 20 5.87373 20C6.16673 20 6.44153 19.8865 6.64738 19.6805L15.5559 10.7723C15.7623 10.5656 15.8756 10.2897 15.8749 9.99634C15.8756 9.70187 15.7623 9.4261 15.5559 9.2196Z"
      fill="#682C8F"
    />
  </svg>
);

export default Arrow;
