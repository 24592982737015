const WISHES_INFORMATION = [
  {
    id: 'how_to_get_rid_of_pigmentation',
    title: 'Позбутися пігментації',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1.1',
            text: 'Прикро, але гіперпігментація – одна з проблем, які беруться буквально нізвідки. Ще вчора ви безтурботно ніжилися на сонечку, а сьогодні шкіра вкрилася темними плямами всіх кольорів і форм. Однак незалежно від проявів – чи то мелазма, веснянки чи темні кола після загоєння прища – передусім необхідне глибоке розуміння суті проблеми, її причин. ',
          },
          {
            id: '1.2',
            text: 'Гіперпігментація виникає, коли організм надлишково виробляє меланін (пігмент, який надає шкірі, волоссю й очам природного кольору) на деяких ділянках шкіри. Веснянки, вікові плями й сліди після акне – все це приклади гіперпігментації. Хоча вона може виникнути в кожної людини в будь-який момент життя, гіперпігментація найчастіше зустрічається у людей зі середньо-смаглявим і темним тоном шкіри. Це відбувається тому, що в темній шкірі міститься більше меланіну й, відповідно, вона більш схильна до пігментації. І хоча проблема пігментації вважається нешкідливою, все ж вона може викликати сильний дискомфорт. Тим більше, що порушення пігментації найчастіше проявляються саме на відкритих ділянках тіла: на обличчі, шиї, декольте, руках.',
          },
          {
            id: '1.3',
            text: 'На пігментацію впливають жіночі статеві гормони – естрогени, тому гіперпігментація іноді з’являється під час вагітності, при прийомі гормональних препаратів (контрацептивів). Ряд внутрішніх захворювань також призводить до посилення пігментації шкіри. Залишкова гіперпігментація на місці запального процесу є частим наслідком вугрової хвороби (акне). Пігментація на місці запального процесу може пройти самостійно, але частіше потребує додаткового косметологічного догляду.',
          },
          {
            id: '1.4',
            text: 'Для боротьби з гіперпігментацією оптимально поєднувати салонні процедури і правильно підібрані засоби домашнього догляду, звичайно, за умови, що по можливості усунута причина, яка спровокувала проблему.',
          },
        ],
      },
      {
        id: '2',
        type: 'textOnly',
        content: [
          {
            id: '2.1',
            text: 'Пропонуємо курс професійних процедур, спрямованих на лікування тьмяної, гіперпігментованої шкіри, а саме:',
          },
        ],
      },
      {
        id: '3',
        type: 'procedure',
        title: 'Курс пілінгів',
        link: 'peeling',
        content: [
          {
            id: '3.1',
            text: 'Основа всіх пілінгів — це дія на певні шари шкіри, як наслідок - оновлення шкіри, омолодження та ефективне очищення. Пілінги чудово вирішують питання з гіперпігментацією, роблять шкіру гарною, здоровою та молодою. Ефективність хімічних пілінгів обумовлена реакцією організму на мікропошкодження шкіри, пілінг активізує регенеративні процеси в клітинах шкіри, в результаті чого обмінні процеси пришвидшуються. Це дозволяє усунути неглибоку пігментацію, заразом і оновити верхній шар епідермісу, відновити здоровий колір обличчя.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Алмазна мікродермабразія',
        link: 'microdermabrasion',
        content: [
          {
            id: '4.1',
            text: 'Алмазне шліфування, механічний пілінг - видалення дрібних дефектів шкіри, запуск регенераційних процесів апаратом, оснащеним насадками з алмазним напиленням, які працюють за принципом щітки, прибираючи зі шкіри омертвілі лусочки, сальні пробки, забруднення. Ефективність посилюється вакуумною системою апарату, яка втягує відшаровані клітини епідермісу та стимулює кровообіг в шкірі, має низьку алергенність і травматичність, відсутність больових відчуттів. Мікродермабразія стимулює вироблення колагену та еластину, завдяки чому шкіра отримує новий життєвий імпульс. При цьому значно зменшуються вікові пігментні плями. Покращення стану шкіри буде помітно вже після першої процедури.',
          },
        ],
      },
      {
        id: '5',
        type: 'mesotherapy',
        title: 'Мезотерапія відбілюючими коктейлями',
        link: 'microdermabrasion',
        content: [
          {
            id: '5.1',
            text: 'Ін`єкційний метод дозволяє доставити активні речовини прямо в клітини епідермісу, діючи на пігментні плями з середини та зупиняючи вироблення пігментів. ',
          },
        ],
      },
      {
        id: '6',
        type: 'procedure',
        title: 'Електропорація',
        link: 'electroporation',
        content: [
          {
            id: '6.1',
            text: 'Це безін`єкційна мезотерапія, в ході якої під впливом електричних імпульсів вводяться лікарські або косметологічні препарати. Аналогом голки виступає електричний струм, що забезпечує збільшення проникності мембрани адипоцитів, посилює міжклітинний метаболізм і видаляє продукти розпаду.',
          },
        ],
      },

      {
        id: '7',
        type: 'procedure',
        title: 'Мікроструми',
        link: 'microcurrents',
        content: [
          {
            id: '7.1',
            text: 'Базова лікувальна процедура, одна з найпопулярніших у загальному переліку методик апаратного догляду. Процедура, пов’язана з впливом електричного струму на шкіру, всю глибину м’яких тканин. Мікроструми впливають не лише на верхній шар шкіри – епідерміс. Глибина його проходження всередину зачіпає всі м’які тканини – дерму, мембрану клітинок, підшкірно-жирову клітковину, м’язовий шар, кровоносні і лімфатичні судини. Саме цим і визначається ефективність мікрострумової терапії.',
          },
        ],
      },
      {
        id: '8',
        type: 'procedure',
        title: 'Косметичний догляд',
        link: 'cosmetic_care',
        content: [
          {
            id: '8.1',
            text: 'Комплексний догляд за шкірою засобами професійних космецевтичних преміум-марок включає в себе очищення, живлення і зволоження. Всі засоби містять відбілюючі компоненти, що дозволяють зменшити прояви пігментації на шкірі та попередити ї подальше розповсюдження.',
          },
        ],
      },
      {
        id: '9',
        type: 'textOnly',
        content: [
          {
            id: '9.1',
            text: 'Для домашнього догляду індивідуально підбираються косметичні засоби, що містять відбілюючі та блокуючі меланогенез компоненти. ',
          },
          {
            id: '9.2',
            text: 'Гіперпігментація - проблема рецидивуюча, тому використання сонцезахисних засобів цілий рік для такої шкіри обов”язкове. Влітку, коли сонце особливо активне, захисний фактор має бути не менше 40-50 SPF для обличчя і 30 SPF для тіла. Також важливо регулярно оновлювати захист, тому що його дія послаблюється з часом та під дією води.',
          },
        ],
      },

      {
        id: '10',
        type: 'textOnly',
        content: [
          {
            id: '10.1',
            text: 'Уникнення надмірного перебування на сонці та застосування сонцезахисних засобів для шкіри значно зменшить можливість появи гіперпігментації шкіри та інтенсивності забарвлення існуючих пігментних плям.',
          },
        ],
      },
    ],
  },
  {
    id: 'how_to_get_rid_of_stretch_marks',
    title: 'Позбутися стриїв',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Розтяжки, також відомі як стрії — одна з форм рубців на шкірі, що мають інший відтінок. З часом вони можуть зменшитися, але не зникають повністю. Розтяжки формуються під час швидкого росту організму, наприклад, під час статевого дозрівання або вагітності. Під час вагітності вони, як правило, формуються на останньому триместрі, і зазвичай на животі, але часто виникають і на грудях, стегнах, попереку і сідницях — цей різновид називають «стрії вагітних». Розтяжки є наслідком розривів дерми, які часто стаються внаслідок швидкого розтягування шкіри під час швидкого росту або зміни ваги.',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Біоревіталізація',
        link: 'biorevitalization',
        content: [
          {
            id: '1',
            text: 'Введення в шкіру спеціальних коктейлів на основі гіалуронової кислоти, в результаті чого зволожується дерма, відновлюється природній тургор, стимулюється синтез колагену та еластину, активізується ріст клітин. Більше того, в епідермісі починає активніше вироблятися власна гіалуронова кислота та інші складники міжклітинного матриксу. Запускаються процеси регенерації дерми, від чого шкіра стає пружнішою та підтягнутою, а розтяжки менш помітними. Дієвість процедури помітна вже після першої процедури.',
          },
        ],
      },
      {
        id: '3',
        type: 'procedure',
        title: 'Плазмоліфтинг',
        link: 'plasmalifting_body',
        content: [
          {
            id: '1',
            text: 'Безпечна, високоефективна та універсальна процедура введення в глибокі шари епідермісу власної плазми, насиченої тромбоцитами, що прискорює процеси заживлення пошкоджених тканин, оновлення клітин і відновлення щільності шкіри, покращення обмінних процесів у верхніх шарах дерми, покращує пружність та еластичність шкіри.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy_body',
        content: [
          {
            id: '1',
            text: 'Ін`єкційний метод дозволяє доставити активні речовини особливих коктейлів прямо в клітини епідермісу для його активної регенерації.',
          },
        ],
      },
      {
        id: '5',
        type: 'procedure',
        title: 'Курс пілінгів',
        link: 'peeling',
        content: [
          {
            id: '1',
            text: 'Поверхневі пілінги - найбезпечніша дія хімічних препаратів, ультразвука, шліфовки і вакуума на роговий шар дерми з ціллю прискорення процесів клітинного оновлення та вирівнюванню рельєфу шкіри. Під час процедури клієнт почуває себе абсолютно комфортно, а по її завершенню не потрібно реабілітації. Якість шкіри покращується після першої ж процедури, проте для більш вираженого ефекту необхідно пройти цілий курс. Серединні пілінги - ще одна косметична процедура для видалення з поверхні шкіри розтяжок і рубців. Методика видалення ороговілих клітин епідермісу, активізації відновних процесів дією на шкіру спеціальних хімічних речовин.',
          },
        ],
      },
      {
        id: '6',
        type: 'procedure',
        title: 'Алмазна мікродермабразія',
        link: 'microdermabrasion_body',
        content: [
          {
            id: '1',
            text: 'Алмазне шліфування, механічний пілінг - видалення дрібних дефектів шкіри, запуск регенераційних процесів апаратом, оснащеним насадками з алмазним напиленням, які працюють за принципом щітки, прибираючи зі шкіри омертвілі лусочки, сальні пробки, забруднення. Ефективність посилюється вакуумною системою апарату, яка втягує відшаровані клітини епідермісу та стимулює кровообіг в шкірі, має низьку алергенність і травматичність, відсутність больових відчуттів. Мікродермабразія стимулює вироблення колагену та еластину, завдяки чому шкіра отримує новий життєвий імпульс. Інноваційна процедура отримала популярність за рахунок миттєвого ефекту.',
          },
        ],
      },
      {
        id: '7',
        type: 'procedure',
        title: 'Процедура RF-ліфтингу',
        link: 'RF-lifting_body',
        content: [
          {
            id: '1',
            text: 'Нова альтернатива пластичним операціям, спрямованим на омолодження і підтяжку шкіри обличчя і тіла. Процедура, заснована на контрольованому прогріванні глибоких шарів епідермісу і підшкірно-жирової клітковини за рахунок радіочастотного впливу, дозволяє домогтися довгострокового ефекту омолодження. Під дією тепла прискорюються процеси обміну речовин, що стимулює вироблення колагену. В наслідок прогрівання в шкірі, що поступово втратила частину своїх функцій, стала в`ялою, обвислою, зморшкуватою, посилюється крово- і лімфовідтік, активізується обмін речовин, відновлюється робота фібробластів (виробляють гіалуронову кислоту, фібрин та речовини, які є попередником колагенових і еластинових волокон). Волокна повертають тонус, стискаються і заново формують молоду і пружну шкіру. ',
          },
        ],
      },
    ],
  },
  {
    id: 'how_to_get_rid_of_double_chin',
    title: 'Позбутися другого підборіддя',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Проблема другого підборіддя хвилює багатьох сучасних людей, як жінок, так і чоловіків. З віком наш організм виробляє менше колагену, що і призводить до провисання шкіри на деяких ділянках. І в першу чергу це відбувається в зоні підборіддя. Також даний недолік виникає при зайвій вазі, гормональних порушеннях, захворюваннях щитовидної залози, порушенні постави. Даний недолік усувається абсолютно безпечними, доступними і не потребуючими тривалої реабілітації методами.',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy',
        content: [
          {
            id: '1',
            text: 'Один із методів боротьби з другим підборіддям. Ін`єкції спеціальних препаратів (ліполітиків та мезококтейлів)) призводять до руйнування жирової тканини, відновлення тургору, покращують мікроциркуляцію. тонус і пружність шкіри.',
          },
        ],
      },
      {
        id: '3',
        type: 'procedure',
        title: 'RF-ліфтинг',
        link: 'RF-lifting',
        content: [
          {
            id: '1',
            text: 'Активно використовується для спалювання жирових відкладень в зоні підборіддя незалежно від віку шкіри, викликає мікроскопічні зміни в тканинах і ремоделює новий колаген. Гарно справляється з задачею зміни контуру обличчя і шиї. При цьому покращується кровотік і виробляється новий білок, який і створює ефект підтяжки.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Ультразвукова кавітація',
        link: 'cavitation',
        content: [
          {
            id: '1',
            text: 'Метод усунення жирових відкладень, заснований на впливі звукових хвиль. Її ще називають безопераційною ліпосакцією через виражений жироспалюючий ефект. За допомогою процедури можна безболісно домогтися помітних результатів, опрацювати проблемні ділянки. Вона допомагає позбутися локальних жирових відкладень, не зачіпаючи інші частини тіла.',
          },
        ],
      },
      {
        id: '5',
        type: 'procedure',
        title: 'Електропорація',
        link: 'electroporation',
        content: [
          {
            id: '1',
            text: 'Це безін`єкційна мезотерапія, в ході якої під впливом електричних імпульсів вводяться лікарські або косметологічні препарати. Аналогом голки виступає електричний струм, що забезпечує збільшення проникності мембрани адипоцитів, посилює міжклітинний метаболізм і видаляє продукти розпаду.',
          },
        ],
      },
      {
        id: '6',
        type: 'procedure',
        title: 'Мікроструми',
        link: 'microcurrents',
        content: [
          {
            id: '1',
            text: 'Базова лікувальна процедура, одна з найпопулярніших у загальному переліку методик апаратного догляду. Процедура, пов’язана з впливом електричного струму на шкіру, всю глибину м’яких тканин. Специфіка задіяного струму – вкрай мала амплітуда і низькі частоти, їх застосування підтягує м’язи, прибирає набряклість. Одні м’язи, які «тримають» обличчя в молодості, з часом спазмуються, опускаються, розслабляються, інші – навпаки, приходять в гіпотонус. Опускаються куточки очей, рота, напружуються м’язи шиї, чола, обличчя «пливе». Під час процедури терапії з використанням мікрострумів відбувається скорочення м’язових тканин – деліктне і щадне за рахунок мікродоз електроструму. Вибираючи напрямок руху електродів, майстер працює над усуненням конкретних проблем шкіри клієнта.',
          },
        ],
      },
    ],
  },
  {
    id: 'how_to_get_rid_of_body_fat',
    title: 'Позбутися жирових відкладень',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Не всім вдається отримати ідеальну фігуру за допомогою спорту та правильного харчування. Іноді це не допомагає або вимагає занадто багато зусиль та часу. Крім того, проблемні місця нерідко з`являються навіть у струнких людей. Зробити фігуру стрункішою, прибравши зайвий об`єм, можливо за допомогою ліполізу, процедури, що забезпечує розщеплення жирових відкладень та, як результат, зменшення об`ємів та корекцію фігури. Ліполіз – це один із сучасних методів боротьби з целюлітом та зайвою вагою. Він не вимагає хірургічного втручання і застосування наркозу, як, наприклад, ліпосакція, але при цьому має високу ефективність та безпеку.',
          },
          {
            id: '2',
            text: 'Ліполіз забезпечує зменшення об’ємів тіла за рахунок розщеплення жирових клітин. За допомогою процедури можна позбутися як від целюліту, так і жирового прошарку, щоб зробити рельєфи тіла більш чіткими.',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy_body',
        content: [
          {
            id: '1',
            text: 'Ін`єкційний ліполіз – передбачає введення під шкіру ліполітиків, які викликають розщеплення жирових відкладень.',
          },
        ],
      },
      {
        id: '3',
        type: 'procedure',
        title: 'Ультразвукова кавітація',
        link: 'cavitation',
        content: [
          {
            id: '1',
            text: 'Ультразвуковий ліполіз – руйнування жирових клітин досягають за допомогою УЗ-хвиль різної частоти, ще називають безопераційною ліпосакцією через виражений жироспалюючий ефект. За її допомоги зменшуються обсяги жирових відкладень на 1-3 сантиметри за одну процедуру. За допомогою процедури можна безболісно домогтися помітних результатів, опрацювати проблемні ділянки та зменшити прояви целюліту. Вона допомагає позбутися локальних жирових відкладень, не зачіпаючи інші частини тіла.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'RF-ліфтинг',
        link: 'RF-lifting_body',
        content: [
          {
            id: '1',
            text: 'Радіочастотний ліполіз – передбачає прогрівання тканин за допомогою електромагнітних хвиль, що також провокує розпад жирових відкладень.',
          },
        ],
      },
      {
        id: '5',
        type: 'procedure',
        title: 'Пресотерапія',
        link: 'pressure_therapy',
        content: [
          {
            id: '1',
            text: 'Максимально ефективний результат можна отримати в поєднанні цих технік з пресотерапією. Це метод, заснований на ритмічній стимуляції м’язів у певній послідовності з метою стимуляції  природного дренажу  лімфи і зменшення вмісту рідини і токсинів у тканинах організму. Цей метод ефективний при лікуванні целюліту, ожирінні, набряках та інших захворюваннях, пов’язаних з порушенням циркуляції крові та лімфи, а також є хорошою профілактикою варикозної хвороби вен нижніх кінцівок, виводить з організму надлишки рідини і шкідливі токсичні речовини, відновлює водний баланс (що пояснюється нормалізацією циркуляції лімфи і міжклітинної рідини), поліпшує венозний кровообіг, активізує обмінні процеси в клітинах шкіри і жирових клітинах(стимулює рецептори клітини, що відповідальні за розщеплення жиру).',
          },
        ],
      },
    ],
  },

  {
    id: 'how_to_get_rid_of_wrinkles',
    title: 'Позбутися зморшок',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Починаючи приблизно з 25-річного віку, на поверхні шкіри починають проявлятися ознаки загального старіння шкіри. З віком процеси у шкірі сповільнюються, а структура слабшає. Речовин, які роблять шкіру пружною і гладенькою, стає менше. Це пов`язано з поєднанням впливу внутрішніх процесів старіння організму із зовнішніми факторами. Одна з найбільш помітних перших ознак старіння — це зморшки, які з часом поглиблюються. Проблема зморшок може ефективно і безпечно вирішитися універсальними процедурами омолодження та відновлення шкіри.',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Біоревіталізація',
        link: 'biorevitalization',
        content: [
          {
            id: '1',
            text: 'Введення в проблемну зону коктейлю з препаратів, основою якого є гіалуронова кислота, що дає ефект зволоження дерми, відновлення пружності і еластичності шкіри. Стимулюється синтез колагену та еластину, активізується ріст клітин. Більше того, в епідермісі починає активніше вироблятися власна гіалуронова кислота та інші складники міжклітинного матриксу. Запускаються процеси регенерації дерми. ',
          },
        ],
      },

      {
        id: '3',
        type: 'procedure',
        title: 'Плазмотерапія',
        link: 'plasmalifting',
        content: [
          {
            id: '1',
            text: 'Введення глибоко в епідерміс власної плазми, насиченої тромбоцитами. Завдяки чому клітини оновлюються, відновлюється щільність  шкіри, покращуються обмінні процеси у верхніх шарах дерми. Відповідно покращується пружність та еластичність шкіри, а наявні зморшки розгладжуються.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy',
        content: [
          {
            id: '1',
            text: 'Допоможе позбутися ознак старіння шкіри у вигляді зморшок. В цьому випадку в проблемну зону вводиться спеціальний мезококтейль з різних речовин, які стимулюють регенерацію тканин. Ефект має пролонговану дію та сумісний з іншими косметичними процедурами.',
          },
        ],
      },
      {
        id: '5',
        type: 'procedure',
        title: 'Мікродермабразія',
        link: 'microdermabrasion',
        content: [
          {
            id: '1',
            text: 'Алмазне шліфування, механічний пілінг - видалення дрібних дефектів шкіри, запуск регенераційних процесів апаратом, оснащеним насадками з алмазним напиленням, які працюють за принципом щітки, прибираючи зі шкіри омертвілі лусочки, сальні пробки, забруднення. Ефективність посилюється вакуумною системою апарату, яка втягує відшаровані клітини епідермісу та стимулює кровообіг в шкірі, має низьку алергенність і травматичність, відсутність больових відчуттів. Мікродермабразія стимулює вироблення колагену та еластину, завдяки чому шкіра отримує новий життєвий імпульс. Інноваційна процедура отримала популярність за рахунок миттєвого ефекту;',
          },
        ],
      },
      {
        id: '6',
        type: 'procedure',
        title: 'Пілінги',
        link: 'peeling',
        content: [
          {
            id: '1',
            text: 'Поверхневий пілінг активізує обмінні процеси, видаляє ороговілі клітини, вирівнює рельєф шкіри, стимулює клітини та нервові закінчення, прискорює процес утворення фібробластів, які відповідають за вироблення еластину та колагену; серединний пілінг очищає шкіру від ороговілих клітин та виводить токсини, при цьому прискорює регенерацію тканин, стимулює вироблення колагенових волокон, які відповідають за пружність та молодість шкіри.',
          },
        ],
      },
      {
        id: '7',
        type: 'procedure',
        title: 'RF-ліфтинг',
        link: 'RF-lifting',
        content: [
          {
            id: '1',
            text: 'Нова альтернатива пластичним операціям, спрямованим на омолодження і підтяжку шкіри обличчя і тіла. Процедура, заснована на контрольованому прогріванні глибоких шарів епідермісу і підшкірно-жирової клітковини за рахунок радіочастотного впливу, дозволяє домогтися довгострокового ефекту омолодження. Під дією тепла прискорюються процеси обміну речовин, що стимулює вироблення колагену. В наслідок прогрівання в шкірі, що поступово втратила частину своїх функцій, стала в`ялою, обвислою, зморшкуватою, посилюється крово- і лімфовідтік, активізується обмін речовин, відновлюється робота фібробластів (виробляють гіалуронову кислоту, фібрин та речовини, які є попередником колагенових і еластинових волокон). Волокна повертають тонус, стискаються і заново формують молоду і пружну шкіру. Процедура має пролонговану дію, ефект накопичується і покращується від процедури до процедури.',
          },
        ],
      },
      {
        id: '8',
        type: 'procedure',
        title: 'Мікроструми',
        link: 'microcurrents',
        content: [
          {
            id: '1',
            text: 'Одна з найпопулярніших процедур у загальному переліку методик апаратного догляду. Специфіка задіяного струму – вкрай мала амплітуда і низькі частоти, їх застосування в прямому сенсі слова омолоджує шкіру, лікує її, підтягує м’язи, прибирає набряклість, «відкриває очі». Мікроструми впливають не лише на верхній шар шкіри – епідерміс. Глибина його проходження всередину зачіпає всі м’які тканини – дерму, мембрану клітинок, підшкірно-жирову клітковину, м’язовий шар, кровоносні і лімфатичні судини. Саме цим і визначається ефективність мікрострумової терапії. Одні м’язи, які «тримають» обличчя в молодості, з часом спазмуються, опускаються, розслабляються, інші – навпаки, приходять в гіпотонус. Опускаються куточки очей,  рота, напружуються м’язи шиї, чола, обличчя «пливе». Під час процедури терапії з використанням мікрострумів відбувається скорочення м’язових тканин – деліктне і щадне за рахунок мікродоз електроструму. Вибираючи напрямок руху електродів, майстер працює над усуненням конкретних проблем шкіри клієнта.',
          },
        ],
      },
      {
        id: '9',
        type: 'procedure',
        title: 'Косметичний догляд',
        link: 'cosmetic_care',
        content: [
          {
            id: '1',
            text: 'Комплексний догляд за шкірою засобами професійних космецевтичних преміум-марок включає в себе очищення, живлення і зволоження, ліфтинг, детокс. Всі ці засоби містять ферменти, антиоксиданти, вітаміни та мікроелементи, необхідні для ефективної регенерації шкіри. Для домашнього догляду індивідуально підбираються косметичні засоби, що містять необхідні омолоджуючі компоненти. ',
          },
        ],
      },
    ],
  },
  {
    id: 'how_to_get_rid_of_hair_loss',
    title: 'Позбутися випадіння волосся',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'На стан волосся впливає дуже багато речей. Це і генетика, і харчування, і зовнішні чинники. Нерідко волосся стає ламким та тьмяним або починає випадати. Такі проблеми знайомі як жінкам, так і чоловікам. На щастя, сучасна косметологія дозволяє оздоровити волосся та повернути йому красу та розкішний об’єм.	',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy',
        content: [
          {
            id: '1',
            text: 'Це ін`єкційна процедура, яка передбачає введення під шкіру спеціальних косметичних коктейлів. У них додають лікувальні препарати, вітаміни, мікроелементи та інші компоненти, що сприяють відновленню волосся. Завдяки прицільному введенню препарату, а також ефекту голкотерапії така процедура забезпечує високу ефективність. Мезотерапію застосовують навіть при алопеції. Процедура підходить жінкам та чоловікам від 18 років. Кожному пацієнту препарат для мезотерапії готують індивідуально, додаючи в нього цілий комплекс різних компонентів. Їх ефективність підвищується завдяки введенню безпосередньо в проблемну зону. В цей же час самі мікроін`єкції покращують циркуляцію крові та забезпечення фолікулів корисними речовинами. Така процедура пробуджує «сплячі» фолікули та збагачує волосся, оздоровлюючи його зсередини. Крім того, вона нормалізує стан шкіри голови та дозволяє позбутися лупи, себореї, надмірної сухості або жирності, прискорити ріст волосся, відновити його густоту та нормалізувати гідроліпідний баланс шкіри.',
          },
        ],
      },

      {
        id: '3',
        type: 'procedure',
        title: 'Дарсонвалізація',
        link: 'darsonvalization',
        content: [
          {
            id: '1',
            text: 'Метод апаратної косметології, що ґрунтується на використанні змінного імпульсного струму високої частоти і напруги та малої сили. Імпульси струму, подразнюючи периферичні нервові закінчення, сприяють розширенню артеріальних і венозних судин, збільшенню проникності судинних стінок, стимуляції обмінних процесів, тонізують шкіру, мають підсушувальну і протизапальну дію, підсилюють крово- і лімфообіг, і, як наслідок, кровопостачання волосяних цибулин. Додатково дарсонвалізація стимулює «сплячі» цибулини, забезпечуючи зростання нового волосся.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Косметичний догляд',
        link: 'cosmetic_care',
        content: [
          {
            id: '1',
            text: 'Сучасні виробники засобів для волосся пропонують безліч масок, лосьйонів, шампуней, основна ціль яких - здорове, густе, блискуче волосся. Професійна косметика допомагає ефективно вирішити проблеми з волоссям. Основна задача шампуню - ретельне очищення шкіри голови і волосся від різноманітного забруднення. Лосьйони та маски містять вітаміни, мікроелементи, кератинові комплекси, натуральні олії, тобто вони живлять шкіру та покращують структуру волосся. Комплексне застосування цих засобів дає можливість посилити кровообіг в ділянці волосяної частини голови, привести в норму функціонування сальних залоз, активувати ріст волосся, прибрати сухість та лущення шкіри.',
          },
        ],
      },
    ],
  },
  {
    id: 'how_to_get_rid_of_acne',
    title: 'Позбутися прищів',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Вугрова хвороба — хронічне запальне захворювання шкіри, яке виникає внаслідок запалення та закупорювання сальних залоз та характеризується розвитком вугрових висипань переважно на шкірі обличчя, спини і грудей.  ',
          },
          {
            id: '2',
            text: 'Сальні залози шкіри виробляють надмірну кількість шкірного сала. Через це пори закупорюються мертвими клітинами і шкірним салом. Іноді процес зупиняється на цьому етапі, через що виникають чорні та білі вугрі. У більш важких випадках бактерії розмножуються в шкірному салі і викликають запалення на шкірі, внаслідок чого виникають поверхневі прищі, які називаються пустулами, або глибші, важкі і болючі ураження, які називаються вузлами і міхурцями. Характерні для акне вугрові висипання можуть суттєво знижувати якість життя, призводити до формування серйозних психологічних та соціальних проблем. Неадекватне лікування або його відсутність взагалі часто призводять до формування постакне — стійких змін шкіри у вигляді рубців, телеангіектазій та дисхромій, які з труднощами піддаються естетичній корекції.',
          },
          {
            id: '3',
            text: 'Головна причина вугрового висипу — гормональна. Однак існує кілька інших факторів, які сприяють виникненню прищів, серед яких спадковість, вживання окремих препаратів (стероїдів), частий контакт рук або телефону з обличчям та стрес. Вугрі помилково вважають підліткової хворобою, яка виникає в період статевого дозрівання. Та причин, насправді, у неї багато, й доволі часто вона вражає людей зрілого віку. Більшість людей починають лікування акне самостійно, за допомогою різноманітних кремів і лосьйонів. Вони вважають, що це звичайні нешкідливі прищі, які тільки псують зовнішній вигляд. Але, вугровий висип – медична проблема, і з приводу лікування акне варто звертатися до лікаря. Лікування акне має бути спрямоване на корекцію факторів, які спричиняють розвиток вугрових висипань.',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Курс пілінгів',
        link: 'peeling',
        content: [
          {
            id: '1',
            text: 'Пілінги - основа естетичної косметології. Допомогти своєчасному оновленню клітин шкіри допоможе пілінг. Пілінг (ексфоліація) – це косметична процедура, під час якої відлущують, а потім видаляють неживі клітини ороговілого верхнього шару шкіри.   ',
          },
          {
            id: '2',
            text: 'Ультразвуковий пілінг делікатний та безпечний, який майже немає протипоказів. Така чистка рекомендована як підліткам, так і віковим клієнтам, підходить для всіх типів шкіри. Головне завдання такого догляду - чистка шкіри: чисті пори, рівний колір і рельєф шкіри. В основі технології – низькочастотний ультразвук, який надає тиск на шкіру, що є мікромасажем на клітинному рівні. Також під час проведення ультразвукового пілінгу відбувається акустична хвиля. Її вплив проявляється в зменшенні набряклості внаслідок поліпшення крово- і лімфообігу. Один з найпростіших і безболісних способів очищення шкіри від: забруднень, ороговілих клітин, шкірногосала. ',
          },
          {
            id: '3',
            text: 'Крім того, процедура сприяє очищенню сальних залоз і видаленню комедонів, розсмоктуванню рубців та застійних плям. Хімічні пілінги – косметологічна процедура, ціллю якої є злущення (ексфоліація) шарів шкіри за допомогою контрольованих хімічних агентів (кислот). Дію кислот шкіра сприймає, як пошкодження і починає активно відновлюватись: збільшується вироблення (синтез) колагену, еластину, гіалуронової кислоти, прискорюється відновлення клітин. Як результат – покращується зовнішній вигляд і текстура шкіри, звужуються пори, регулюється діяльність сальних залоз.',
          },
        ],
      },

      {
        id: '3',
        type: 'procedure',
        title: 'Алмазна мікродермабразія',
        link: 'microdermabrasion',
        content: [
          {
            id: '1',
            text: 'Алмазне шліфування, механічний пілінг - видалення дрібних дефектів шкіри, запуск регенераційних процесів апаратом, оснащеним насадками з алмазним напиленням, які працюють за принципом щітки, прибираючи зі шкіри омертвілі лусочки, сальні пробки, забруднення. Ефективність посилюється вакуумною системою апарату, яка втягує відшаровані клітини епідермісу та стимулює кровообіг в шкірі, має низьку алергенність і травматичність, відсутність больових відчуттів. Ефект: очищення  шкіри від забруднень, звуження пор, зменшення маснистості шкіри, розгладження, шліфування поверхневих шрамів та постакне, підвищення тонусу та еластичності шкіри, покращення кольору обличчя.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy',
        content: [
          {
            id: '1',
            text: 'Ін`єкційний метод дозволяє доставити активні речовини особливих коктейлів прямо в клітини епідермісу. В цьому  випадку в проблемну зону вводиться спеціальний мезококтейль з різних речовин, які стимулюють регенерацію тканин. Ефект має пролонговану дію та сумісний з іншими косметичними процедурами.',
          },
        ],
      },
      {
        id: '5',
        type: 'procedure',
        title: 'Дарсонвалізація',
        link: 'darsonvalization',
        content: [
          {
            id: '1',
            text: 'Метод апаратної косметології, що ґрунтується на використанні змінного імпульсного струму високої частоти і напруги та малої сили. Імпульси струму, подразнюючи периферичні нервові закінчення, сприяють розширенню артеріальних і венозних судин, збільшенню проникності судинних стінок, стимуляції обмінних процесів, тонізують шкіру, мають підсушувальну і протизапальну дію, підсилюють крово- і лімфообіг.',
          },
        ],
      },
      {
        id: '6',
        type: 'procedure',
        title: 'Масаж обличчя за Жаке',
        link: 'cosmetic_facial_massage',
        content: [
          {
            id: '1',
            text: 'Техніка полягає в інтенсивному опрацюванню шкіри защипуванням підшкірних шарів. Щипкова техніка була винайдена Леонардом Жаке, французьким дерматологом. Цією технікою активізуються обмінні процеси в шкірі, стимулюється кровообіг, покращується циркуляція лімфи, запускаються регенераційні процеси в шкірі.',
          },
        ],
      },
      {
        id: '7',
        type: 'procedure',
        title: 'Електропорація',
        link: 'electroporation',
        content: [
          {
            id: '1',
            text: 'Завдяки цій методиці дослідники довели, що під впливом певної потужності і частоти струмів можна вводити глибоко в шкіру макромолекули (великі молекули, які при звичайному нанесенні на шкіру просто залишилися б на ній, не проникаючи крізь роговий шар). При подачі електричних імпульсів в мембранах клітин відбувається тимчасове відкриття мікропор, іонних каналів, через які проходять препарати. В результаті корисні компоненти «коктейлів» проходять глибоко в дерму. Аквапоріни (білки, що формують пори в мембранах клітин) зв`язуються з молекулами лікувальних препаратів і доносять їх глибоко в шкіру. По закінченню дії струмів, мікропори в шкірі самі закриваються.',
          },
          {
            id: '2',
            text: 'Переваги методу: безболісність, без травматизації голкою, безпека, відсутність періоду реабілітації, без синців та гематом, швидке і ефективне запровадження мезококтейля. Застосовується при масній, комбінованій шкірі з великими порами, себореї, комедонах, рубцях, застійних плямах, постакне, нерівній поверхні шкіри внаслідок гіперкератозу.',
          },
        ],
      },
      {
        id: '8',
        type: 'procedure',
        title: 'Біоревіталізація',
        link: 'biorevitalization',
        content: [
          {
            id: '1',
            text: 'Введення в проблемну зону коктейлю з препаратів, основою якого є гіалуронова кислота, що дає ефект зволоження дерми, відновлення пружності і еластичності шкіри. Стимулюється синтез колагену та еластину, активізується ріст клітин. Більше того, в епідермісі починає активніше вироблятися власна гіалуронова кислота та інші складники міжклітинного матриксу. Запускаються процеси регенерації дерми. ',
          },
        ],
      },
      {
        id: '9',
        type: 'procedure',
        title: 'Плазмотерапія',
        link: 'plasmalifting',
        content: [
          {
            id: '1',
            text: 'Введення глибоко в епідерміс власної плазми, насиченої тромбоцитами. Завдяки чому клітини оновлюються, відновлюється щільність шкіри, покращуються обмінні процеси у верхніх шарах дерми, прискорються процеси заживлення пошкоджених тканин.',
          },
        ],
      },
      {
        id: '10',
        type: 'procedure',
        title: 'Косметичний догляд',
        link: 'cosmetic_care',
        content: [
          {
            id: '1',
            text: 'Косметичний догляд за шкірою засобами професійних космецевтичних преміум-марок включає в себе очищення, живлення і зволоження, регенерацію та антибактеріальний ефект. Всі ці засоби містять ферменти, антиоксиданти, вітаміни та мікроелементи, необхідні для ефективної регенерації шкіри. Для домашнього догляду індивідуально підбираються косметичні засоби, що містять необхідні компоненти. ',
          },
        ],
      },
      {
        id: '11',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Акне - проблема рецидивуюча і потребує тривалого, професійного та комплексного лікування.',
          },
        ],
      },
    ],
  },

  {
    id: 'how_to_get_rid_of_couperose',
    title: 'Позбутися куперозу',
    description: [
      {
        id: '1',
        type: 'textOnly',
        content: [
          {
            id: '1',
            text: 'Еритроз - тимчасове або постійне почервоніння шкіри в ділянці щік або носа. ТАЕ - телеангіектазії - стійке розширення судин. Купероз - поєднання ТАЕ і судинної сітки.',
          },
          {
            id: '2',
            text: 'Всі ці прояви є симптомами захворювання судин РОЗАЦЕА. Фактори, що сприяють розвитку: спадковість, чутлива та реактивна шкіра, тонкий тип шкіри, зловживання гострою, гарячею їжею, алкоголем, курінням, банями, саунами, світлий фототип, рудоволосі, клімат (УФО, вітер), наявність демодекозу. В результаті стійкого розширення формується хронічний набряк, застій води в дермі. Дрібні судини стають більш проникні.',
          },
        ],
      },
      {
        id: '2',
        type: 'procedure',
        title: 'Мікроструми',
        link: 'microcurrents',
        content: [
          {
            id: '1',
            text: 'Базова лікувальна процедура, одна з найпопулярніших у загальному переліку методик апаратного догляду. Процедура, пов’язана з впливом електричного струму на шкіру, всю глибину м’яких тканин. Мікроструми впливають не лише на верхній шар шкіри – епідерміс. Глибина його проходження всередину зачіпає всі м’які тканини – дерму, мембрану клітинок, підшкірно-жирову клітковину, м’язовий шар, кровоносні і лімфатичні судини. Саме цим і визначається ефективність мікрострумової терапії.',
          },
        ],
      },

      {
        id: '3',
        type: 'procedure',
        title: 'Мезотерапія',
        link: 'mesotherapy',
        content: [
          {
            id: '1',
            text: 'Ін`єкційний метод дозволяє доставити активні речовини особливих коктейлів прямо в клітини епідермісу. В цьому випадку в проблемну зону вводиться спеціальний мезококтейль з різних речовин, які укріплюють стінки судин, покращують мікроциркуляцію, попереджають утворенню ТАЕ. Ефект має пролонговану дію та сумісний з іншими косметичними процедурами.',
          },
        ],
      },
      {
        id: '4',
        type: 'procedure',
        title: 'Курс пілінгів',
        link: 'peeling',
        content: [
          {
            id: '1',
            text: 'Ензимні та хімічні пілінги - косметологічна процедура, ціллю якої є вироблення (синтез) колагену, еластину, гіалуронової кислоти, прискорюється відновлення клітин. Як результат – покращується зовнішній вигляд і текстура шкіри.',
          },
        ],
      },
      {
        id: '5',
        type: 'procedure',
        title: 'Косметичний догляд',
        link: 'cosmetic_care',
        content: [
          {
            id: '1',
            text: 'Косметичний догляд за шкірою засобами професійних космецевтичних преміум-марок, вітамінотерапія для зменшення ламкості і проникності капілярів та утворення проколагену, збільшення щільності і зменшення проникності судинної стінки. Маски, в основі яких екстракти трав , заспокійливі, регенеруючі і протизапальні засоби. Для домашнього догляду індивідуально підбираються косметичні засоби, що містять необхідні компоненти. ',
          },
        ],
      },
    ],
  },
];

export default WISHES_INFORMATION;
